import * as Sentry from "@sentry/react";
import _ from "lodash";
import type { LinksFunction } from "react-router";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse
} from "react-router";
import SiteNav from "./components/site-nav";
import "./styles/app.css";
import "./styles/slate.css";
import "~/styles/tables.css";
import { useEffect } from "react";
import Alert from "~/components/alert";
import { CurrentUserContext } from "~/contexts";
import { getCurrentUser } from "~/models/access-user.server";
import { getUserId } from "~/session.server";
import type { Route } from "./+types/root";

export const links: LinksFunction = () => {
  return [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Cabin:400,500,700,400italic,500italic,700italic"
    }
  ];
};

export const meta = () => [
  {
    title: "Pete Fowler Construction - Client Access"
  }
];

export const loader = async ({ request }: Route.LoaderArgs) => {
  const id = await getUserId(request);
  return {
    user: id ? (await getCurrentUser(request)).currentAccessUser : null
  };
};

export const ErrorBoundary = ({
  error,
  loaderData
}: Route.ErrorBoundaryProps) => {
  if (!(isRouteErrorResponse(error) && [403, 404].includes(error.status))) {
    Sentry.captureException(error);
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <Meta />
        <Links />
      </head>
      <body>
        <CurrentUserContext.Provider value={loaderData?.user}>
          <div>
            <SiteNav />
            <div className="p-10">
              <Alert mode="danger">
                <div>
                  {isRouteErrorResponse(error) ? (
                    error.status === 404 ? (
                      <div>Page not found</div>
                    ) : error.status === 403 ? (
                      <div>
                        <p>You don't have access to this project.</p>
                        <div>
                          If you should have access, please contact us at{" "}
                          <a href="mailto:helpdesk@petefowler.com">
                            helpdesk@petefowler.com
                          </a>{" "}
                          and we'll get it linked to your account.
                        </div>
                      </div>
                    ) : (
                      <div>
                        {_.isString(error.data)
                          ? error.data
                          : JSON.stringify(error.data)}
                      </div>
                    )
                  ) : (
                    <div>
                      We're sorry — something's gone wrong. We've been notified
                      and will look into this.
                    </div>
                  )}
                </div>
              </Alert>
            </div>
          </div>
        </CurrentUserContext.Provider>
      </body>
    </html>
  );
};

export default function App({ loaderData: { user } }: Route.ComponentProps) {
  useEffect(() => {
    Sentry.setUser(user ? { id: user.id, email: user.login } : null);
  }, [user]);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <CurrentUserContext.Provider value={user}>
          <div>
            <SiteNav />
            <main className="container">
              <Outlet />
            </main>
          </div>
        </CurrentUserContext.Provider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
